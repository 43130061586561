.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #0075A3;
  font-size: 14px;
  line-height: 1.42857143;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  color: #fff !important;
  padding: 10px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border-radius: 100px;
}
.button:hover,
.button:focus {
  color: #fff !important;
  background-color: #FFC400;
}
.button:active {
  background-color: #e6b000;
}
.button-invert {
  background-color: #FFC400;
  color: #0075A3 !important;
}
.button-invert:hover,
.button-invert:focus {
  background-color: #0075A3;
}
.button-invert:active {
  background-color: #00638A;
}
.button-red {
  background-color: #d23040;
  color: #fff !important;
}
.button-red:hover,
.button-red:focus {
  background-color: #0075A3;
}
.button-red:active {
  background-color: #00638A;
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #0075A3;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 16px;
  font-family: 'brandon-grotesque', sans-serif;
  line-height: 1.5;
}
.unit caption {
  display: none;
}
.flag {
  background: #0075A3;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
}
.unit .head,
.unit .body,
.unit .foot {
  float: left;
  width: 100%;
}
.unit.fold .less,
.unit.fold .more {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
}
.pict a {
  float: left;
  width: 100%;
}
#root#root#root img.zoom {
  display: none !important;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
}
#expo .cb-image-figure {
  height: 100%;
}
#expo .cb-image-container {
  height: 100% !important;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
div.load a.load {
  display: inline-block;
  padding-left: 26px;
  background-size: 16px 16px;
  background-position: 0 4px;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-download-black.svg);
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding: 5px 0;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid #000;
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.cb-service {
  float: left;
  width: 100%;
  margin: 5px 0;
}
.cb-service:first-child {
  margin-top: 0;
}
.cb-service:last-child {
  margin-bottom: 0;
}
.cb-youtube iframe,
.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 14px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered li {
  margin-left: 20px;
  list-style-position: outside;
}
.cb-multimood {
  float: left;
  width: 100%;
}
.cb-expo {
  float: left;
  width: 100%;
  position: relative;
}
.cb-expo:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(0, 45, 64, 0.3), transparent);
}
.cb-page-layout3 .cb-expo:after {
  background-image: linear-gradient(to bottom, rgba(0, 45, 64, 0.71), transparent);
}
.cb-slides {
  float: left;
  width: 100%;
  height: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  position: relative;
  padding-bottom: 28.90625%;
}
@media (max-width: 1107px) {
  .cb-slides {
    padding-bottom: 320px;
  }
}
.cb-page-layout3 .cb-slides {
  padding-bottom: 43.359375%;
}
@media (max-width: 1089px) {
  .cb-page-layout3 .cb-slides {
    padding-bottom: 472px;
  }
}
.cb-slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
.cb-slides a {
  display: block;
  width: 100%;
  height: 100%;
}
.cb-slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
.cb-slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.cb-expo div.link {
  display: none;
  float: left;
  width: 100%;
  height: 0;
}
.cb-expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: #0075A3;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
}
.cb-expo a.link.prev {
  left: 20px;
}
.cb-expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #0075A3;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'brandon-grotesque', sans-serif;
  line-height: 1.42857143;
  color: #000;
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #0075A3;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #0075A3;
  font-size: 16px;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  background-color: #0075A3;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  color: #fff !important;
  padding: 10px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border-radius: 100px;
  line-height: 1.42857143;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  color: #fff !important;
  background-color: #FFC400;
}
#disp .foot input:active,
#disp .foot a:active {
  background-color: #e6b000;
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1023px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'brandon-grotesque', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: #F0F0F0;
  min-height: 40px;
  padding: 5px 10px;
  -webkit-appearance: none;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 24px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 8px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  background: #F0F0F0;
  height: 40px;
  border: 1px solid #000;
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form input.submit {
  float: right;
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: #0075A3;
  font-size: 14px;
  line-height: 1.42857143;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  color: #fff !important;
  padding: 10px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border-radius: 100px;
}
.unit.form input.submit:hover,
.unit.form input.submit:focus {
  color: #fff !important;
  background-color: #FFC400;
}
.unit.form input.submit:active {
  background-color: #e6b000;
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: #000;
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
  font-size: 14px;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  background-color: #0075A3;
  font-size: 14px;
  line-height: 1.42857143;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  color: #fff !important;
  padding: 10px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border-radius: 100px;
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  color: #fff !important;
  background-color: #FFC400;
}
.two-step-verification-container a:active {
  background-color: #e6b000;
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 10px;
}
@media (max-width: 767px) {
  .table th {
    padding: 2px 5px;
  }
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}
.table td {
  padding: 5px 10px;
}
@media (max-width: 767px) {
  .table td {
    padding: 2px 5px;
  }
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}
@media (max-width: 767px) {
  .part--table .table {
    font-size: 2.08604954vw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
.cb-home {
  float: left;
}
.whitehome {
  position: absolute;
  left: 40px;
  top: 32px;
  z-index: 2;
  height: 48px;
  display: none;
}
.logo {
  width: auto;
  height: 100%;
}
.cb-page-title {
  float: left;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 15px;
}
.cb-page-layout1 .cb-page-title,
.cb-page-layout3 .cb-page-title {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .cb-page-title {
    margin-top: 50px;
  }
}
.cb-pages {
  float: left;
  width: 100%;
}
.cb-page-selected-layout3 .cb-pages {
  margin-top: 0;
}
.cb-page-selected-layout4 .cb-pages {
  margin-top: 0;
}
.cb-page {
  float: left;
  width: 100%;
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-size: 16px;
  font-family: 'brandon-grotesque', sans-serif;
  line-height: 1.5;
  color: #000;
  overflow: hidden;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 100%;
  width: 944px;
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.cb-page-layout1 .section--main .desk,
.cb-page-layout3 .section--main .desk {
  width: 616px;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2001;
  background-color: #fff;
}
.cb-page-selected-layout4 .section--header {
  display: none;
}
.cb-page-layout2 .section--multimood,
.cb-page-layout4 .section--multimood {
  display: none;
}
.cb-page-layout2.cb-page-color-c2 .section--main {
  background-color: #EFEFEF;
}
.section--main:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 100%;
  width: 100%;
  height: 40px;
  background-image: linear-gradient(to bottom, transparent, rgba(0, 45, 64, 0.75));
  opacity: 0.34;
  pointer-events: none;
}
.cb-page-layout2 .section--main:before {
  display: none;
}
@media (max-width: 767px) {
  .section--main:before {
    display: none;
  }
}
.section--side {
  background-color: #EFEFEF;
}
.section--fullscreen {
  box-sizing: border-box;
  padding: 50px 3%;
  display: none;
}
.cb-page-layout4 .section--fullscreen {
  display: block;
}
.section--footer {
  background-color: #002331;
  color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  -webkit-font-smoothing: antialiased;
}
.cb-page-selected-layout4 .section--footer {
  display: none;
}
.section--footer a {
  color: #fff;
}
.section--footer a:hover,
.section--footer a:focus {
  color: #0075A3;
}
.downlink {
  position: absolute;
  left: 50%;
  bottom: 22px;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  background-size: 40px 18px;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-down-white.svg);
  cursor: pointer;
  transition: all 0.4s;
  display: none;
}
.downlink:hover,
.downlink:focus {
  background-position: 50% 100%;
}
.cb-page-layout3 .downlink {
  display: block;
}
#services,
#mobileservices {
  float: right;
  margin: 30px 40px 0 0;
}
@media (max-width: 767px) {
  #services,
  #mobileservices {
    display: none;
  }
}
#services .meta,
#mobileservices .meta {
  float: left;
  margin: 0 5px;
  font-size: 10px;
  line-height: 1.6;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  color: #0075A3;
  padding-top: 36px;
  background-size: 34px 28px;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-image: url(/images/service-kursplan-blue.svg);
}
#services .meta:first-child,
#mobileservices .meta:first-child {
  margin-left: 0;
}
#services .meta:last-child,
#mobileservices .meta:last-child {
  margin-right: 0;
}
#services .meta:hover,
#mobileservices .meta:hover,
#services .meta:focus,
#mobileservices .meta:focus {
  color: #FFC400;
  background-image: url(/images/service-kursplan-yellow.svg);
}
#services .meta.service_oeffnungszeiten,
#mobileservices .meta.service_oeffnungszeiten {
  background-image: url(/images/service-oeffnungszeiten-blue.svg);
}
#services .meta.service_oeffnungszeiten:hover,
#mobileservices .meta.service_oeffnungszeiten:hover,
#services .meta.service_oeffnungszeiten:focus,
#mobileservices .meta.service_oeffnungszeiten:focus {
  background-image: url(/images/service-oeffnungszeiten-yellow.svg);
}
#services .meta.service_kontakt,
#mobileservices .meta.service_kontakt {
  background-image: url(/images/service-kontakt-blue.svg);
}
#services .meta.service_kontakt:hover,
#mobileservices .meta.service_kontakt:hover,
#services .meta.service_kontakt:focus,
#mobileservices .meta.service_kontakt:focus {
  background-image: url(/images/service-kontakt-yellow.svg);
}
#services .meta.service_standorte,
#mobileservices .meta.service_standorte {
  background-image: url(/images/service-standorte-blue.svg);
}
#services .meta.service_standorte:hover,
#mobileservices .meta.service_standorte:hover,
#services .meta.service_standorte:focus,
#mobileservices .meta.service_standorte:focus {
  background-image: url(/images/service-standorte-yellow.svg);
}
#mobileservices {
  margin-right: 0;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  display: none;
}
@media (max-width: 767px) {
  .cb-page-layout1 #mobileservices,
  .cb-page-layout3 #mobileservices {
    display: flex;
  }
}
#mobileservices .meta {
  margin: 0;
}
#moodservices {
  position: absolute;
  left: 50%;
  bottom: 50px;
  transform: translateX(-50%);
  z-index: 2;
  flex-direction: column;
  align-items: center;
  display: flex;
}
.cb-page-layout3 #moodservices {
  bottom: 100px;
}
#moodservices .meta {
  float: left;
  margin: 6px 0;
  background-color: #0075A3;
  font-size: 14px;
  line-height: 1.42857143;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  color: #fff !important;
  padding: 10px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border-radius: 100px;
}
#moodservices .meta:hover,
#moodservices .meta:focus {
  color: #fff !important;
  background-color: #FFC400;
}
#moodservices .meta:active {
  background-color: #e6b000;
}
#moodservices .meta.service_probetraining,
#moodservices .meta.service_gutschein {
  display: none;
}
@media (max-width: 1179px) {
  #moodservices .meta.service_probetraining,
  #moodservices .meta.service_gutschein {
    display: block;
  }
}
#moodservices .meta.service_infotelefonL,
#moodservices .meta.service_infotelefonB {
  text-transform: none;
}
@media (max-width: 1179px) {
  #moodservices {
    bottom: initial;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
  #moodservices .meta.service_probetraining,
  #moodservices .meta.service_gutschein {
    display: block;
  }
}
#root .service_probetraining {
  float: left;
  background-color: #0075A3;
  font-size: 14px;
  line-height: 1.42857143;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  color: #fff !important;
  padding: 10px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border-radius: 100px;
  background-color: #FFC400;
  color: #0075A3 !important;
  white-space: nowrap;
}
#root .service_probetraining:hover,
#root .service_probetraining:focus {
  color: #fff !important;
  background-color: #FFC400;
}
#root .service_probetraining:active {
  background-color: #e6b000;
}
#root .service_probetraining:hover,
#root .service_probetraining:focus {
  background-color: #0075A3;
}
#root .service_probetraining:active {
  background-color: #00638A;
}
#root .service_gutschein {
  float: left;
  background-color: #0075A3;
  font-size: 14px;
  line-height: 1.42857143;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  padding: 10px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border-radius: 100px;
  background-color: #d23040;
  color: #fff !important;
  white-space: nowrap;
}
#root .service_gutschein:hover,
#root .service_gutschein:focus {
  color: #fff !important;
  background-color: #FFC400;
}
#root .service_gutschein:active {
  background-color: #e6b000;
}
#root .service_gutschein:hover,
#root .service_gutschein:focus {
  background-color: #0075A3;
}
#root .service_gutschein:active {
  background-color: #00638A;
}
#root .section--header .service_probetraining,
#root .section--header .service_gutschein {
  float: right;
  margin: 36px 30px 0 0;
}
@media (max-width: 1179px) {
  #root .section--header .service_probetraining,
  #root .section--header .service_gutschein {
    display: none;
  }
}
#cb-cookie-warning {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1990;
}
#cb-cookie-warning.cb-cookie-warning--hidden {
  display: none !important;
}
.cb-cookie-warning--container {
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  color: #000;
  font-family: 'brandon-grotesque', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  padding: 20px calc((100vw - 944px) / 2);
}
.cb-cookie-warning--container strong {
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}
@media (max-width: 1023px) {
  .cb-cookie-warning--container {
    flex-direction: column;
    padding: 30px 3vw;
  }
}
@media (max-width: 767px) {
  .cb-cookie-warning--container {
    padding: 15px 5vw;
  }
}
.cb-cookie-warning--text {
  float: left;
}
@media (max-width: 1023px) {
  .cb-cookie-warning--text {
    text-align: center;
  }
}
.cb-cookie-warning--actions {
  float: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.cb-cookie-warning__button {
  display: inline-block;
  background-color: #0075A3;
  font-size: 14px;
  line-height: 1.42857143;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  color: #fff !important;
  padding: 10px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border-radius: 100px;
}
.cb-cookie-warning__button:hover,
.cb-cookie-warning__button:focus {
  color: #fff !important;
  background-color: #FFC400;
}
.cb-cookie-warning__button:active {
  background-color: #e6b000;
}
.footarea {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.footpart {
  float: left;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}
.footpart.footpart--2 {
  justify-content: center;
  padding-top: 50px;
  align-items: center;
}
.foottitle {
  float: left;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.footnavi {
  float: left;
  display: flex;
}
.footnavi div.sub1 {
  float: left;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-left: 50px;
}
.footnavi div.sub1:first-child {
  margin-left: 0;
}
.footnavi div.sub1 div.sub2 {
  display: none;
}
.footnavi div.sub1 > .item {
  float: left;
  margin: 2px 0;
}
.footnavi div.sub1 > .item.init {
  margin-top: 0;
}
.footnavi div.sub1 > .item.exit {
  margin-bottom: 0;
}
.footnavi div.sub1 > .item > .menu {
  float: left;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
}
.service_mitglied {
  float: left;
  background-color: #0075A3;
  font-size: 14px;
  line-height: 1.42857143;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  color: #fff !important;
  padding: 10px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border-radius: 100px;
  background-color: #FFC400;
  color: #0075A3 !important;
}
.service_mitglied:hover,
.service_mitglied:focus {
  color: #fff !important;
  background-color: #FFC400;
}
.service_mitglied:active {
  background-color: #e6b000;
}
.service_mitglied:hover,
.service_mitglied:focus {
  background-color: #0075A3;
}
.service_mitglied:active {
  background-color: #00638A;
}
.fitnessguide,
.sichergehen {
  float: left;
  transition: all 0.4s;
}
.fitnessguide img,
.sichergehen img {
  width: 100%;
  height: auto;
}
.fitnessguide:hover,
.sichergehen:hover,
.fitnessguide:focus,
.sichergehen:focus {
  opacity: 0.6;
}
.fitnessguide {
  width: 140px;
  margin-top: 20px;
}
.sichergehen {
  width: 180px;
  margin-top: 8px;
}
@media (max-width: 1023px) {
  .sichergehen {
    margin-right: -20px;
  }
}
@media (max-width: 767px) {
  .sichergehen {
    margin-right: 0;
  }
}
.vcard {
  float: left;
}
.vcard .org {
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
}
.vcard .vcardtitle {
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}
.vcard .tel .type {
  display: inline-block;
  min-width: 56px;
}
.footlinks {
  float: left;
  width: 100%;
  margin: 60px 0 20px;
  font-size: 12px;
  line-height: 1.5;
}
.copyright {
  float: left;
}
#legal {
  float: right;
}
#legal .meta {
  float: left;
}
#legal .meta.service_datenschutz {
  margin-right: 57px;
}
#legal .meta.service_artos {
  margin-right: 106px;
}
.fullscreencontent {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.toplink {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100%;
  margin-bottom: 30px;
  z-index: 2;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/toplink.svg);
  opacity: 0.6;
}
.toplink:hover,
.toplink:focus {
  opacity: 1;
}
@media (max-width: 1023px) {
  .toplink {
    right: 5%;
  }
}
.section--multimood .service_gesundheitspass {
  position: absolute;
  right: 100px;
  bottom: 100px;
  z-index: 10;
  width: 300px;
  height: 300px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/gesundheitspass.png);
  display: none;
}
.cb-page-layout3 .section--multimood .service_gesundheitspass {
  display: block;
}
@media (max-width: 1499px) {
  .section--multimood .service_gesundheitspass {
    width: 210px;
    height: 210px;
    right: 40px;
    bottom: 40px;
  }
}
@media (max-width: 767px) {
  .section--multimood .service_gesundheitspass {
    width: 100px;
    height: 100px;
    right: 20px;
    bottom: 20px;
  }
}
a {
  color: #0075A3;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #FFC400;
}
h1 {
  font-size: 24px;
  line-height: 1.41666667;
  color: #0075A3;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  text-align: center;
}
.cb-page-layout1 h1,
.cb-page-layout3 h1 {
  font-size: 28px;
  line-height: 1.42857143;
}
.cb-page-layout4 h1 {
  font-size: 40px;
  line-height: 1.25;
  border-bottom: 1px solid #0075A3;
  padding-bottom: 20px;
}
h2 {
  font-size: 24px;
  line-height: 1.41666667;
  color: #0075A3;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  text-align: center;
}
h3 {
  font-size: 22px;
  line-height: 1.36363636;
  color: #0075A3;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  text-align: center;
}
h4 {
  font-size: 16px;
  line-height: 1.375;
  color: #0075A3;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
}
h5 {
  font-size: 18px;
  line-height: 1.44444444;
  font-weight: 300;
}
.area {
  margin-top: 60px;
  margin-bottom: 60px;
}
@media (max-width: 767px) {
  .area {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.main {
  margin-top: 0;
}
.cb-page-layout2 .main {
  margin-top: 60px;
}
@media (max-width: 767px) {
  .cb-page-layout2 .main {
    margin-top: 30px;
  }
}
.cb-page-layout1 .main,
.cb-page-layout3 .main {
  text-align: center;
}
.farnorth {
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  font-size: 14px;
  line-height: 1.42857143;
  text-transform: uppercase;
  color: #fff;
  display: flex;
}
@media (max-width: 767px) {
  .farnorth {
    flex-direction: column;
  }
}
#edit .farnorth {
  min-height: 100px;
}
.farnorth strong {
  font-size: 18px;
  line-height: 1.44444444;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}
.farnorth a {
  color: #fff;
  display: block;
}
.farnorth .unit {
  flex-grow: 1;
  margin: 0;
  background-color: #0075A3;
}
.farnorth .unit + .unit {
  background-color: #005D82;
}
.farnorth .unit + .unit + .unit {
  background-color: #0090C9;
}
.farnorth .unit + .unit + .unit + .unit {
  background-color: #007EB0;
}
.farnorth .unit + .unit + .unit + .unit + .unit {
  background-color: #00638A;
}
.farnorth .unit .body {
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.farnorth .unit .part {
  margin: 0;
}
.farnorth .unit .text {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex-grow: 1;
}
.farnorth .unit .text .text-section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex-grow: 1;
}
.farnorth .unit .text .text-section p.norm {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex-grow: 1;
}
.farnorth .unit .text a.open {
  flex-grow: 1;
  padding: 16px 20px 38px;
  background-size: 24px 10px;
  background-position: center bottom 14px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-down-white.svg);
}
.farnorth .unit .text a.open:hover,
.farnorth .unit .text a.open:focus {
  background-color: #FFC400;
  color: #0075A3;
  background-image: url(/images/arrow-down-blue.svg);
}
.cb-page-layout3 .farnorth .unit .text a.open {
  background-image: none;
  padding-top: 27px;
  padding-bottom: 27px;
}
.section--fullscreen .area {
  margin: 0 !important;
}
.cb-page-layout3 .main .seam {
  background-color: #FFC400;
  box-sizing: border-box;
  padding: 15px 30px;
  text-align: left;
}
.cb-page-layout3 .main .seam a {
  color: #0075A3;
}
.cb-page-layout3 .main .seam a:hover,
.cb-page-layout3 .main .seam a:focus {
  color: #000;
}
.cb-page-layout3 .main .seam .part {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
.section--fullscreen .area .unit {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.section--fullscreen .area .unit .part {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.section--fullscreen .area .seam {
  background-color: #EFEFEF;
  box-sizing: border-box;
  padding: 5px 20px;
}
div.link a.open {
  display: inline-block;
  background-color: #0075A3;
  font-size: 14px;
  line-height: 1.42857143;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  color: #fff !important;
  padding: 10px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border-radius: 100px;
}
div.link a.open:hover,
div.link a.open:focus {
  color: #fff !important;
  background-color: #FFC400;
}
div.link a.open:active {
  background-color: #e6b000;
}
div.link a.open[href*='/corona-schutzkonzept'] {
  background-color: #d23040;
  color: #fff !important;
}
div.link a.open[href*='/corona-schutzkonzept']:hover,
div.link a.open[href*='/corona-schutzkonzept']:focus {
  background-color: #0075A3;
}
div.link a.open[href*='/corona-schutzkonzept']:active {
  background-color: #00638A;
}
.cb-page-layout3 .side div.link,
.cb-page-layout3 .farwest div.link {
  text-align: center;
}
.part--table {
  overflow-x: auto;
}
@media (max-width: 1023px) {
  .navi {
    display: none;
  }
}
.togglenavigation {
  float: right;
  position: relative;
  margin: 43px 40px 0 0;
  z-index: 2001;
  width: 42px;
  height: 26px;
  cursor: pointer;
}
@media (max-width: 1023px) {
  .togglenavigation {
    margin-right: 5%;
  }
}
@media (max-width: 767px) {
  .togglenavigation {
    margin-top: 20px;
  }
}
.tline {
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 2px;
  background-color: #0075A3;
  border-radius: 2px;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1), opacity 0.2s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 0;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateX(-20px);
}
body.cb-toggle-target-active .tline--2 {
  transform: rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateX(20px);
}
.mobile-navigation {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2000;
  height: 100%;
  width: 100%;
  max-width: 340px;
  background-color: rgba(0, 117, 163, 0.9);
  overflow-y: scroll;
  transform: translateX(100%);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateX(0);
}
.mobile-navi-animation {
  float: left;
  width: 100%;
  transform: translateX(100px);
  transition: all 1.2s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navi-animation {
  transform: translateX(0);
  transition: all 0.8s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.mobile-navigation div.navi {
  float: left;
  width: 100%;
  display: block;
  position: relative;
}
.mobile-navigation div.navi > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item {
  float: left;
  width: 100%;
  position: relative;
}
.mobile-navigation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item > .menu {
  display: block;
  color: #fff;
}
.mobile-navigation div.navi > .item > .menu.path {
  color: #FFC400;
}
.mobile-navigation div.sub1 {
  box-sizing: border-box;
  padding: 0 40px;
  margin-top: 152px;
  margin-bottom: 50px;
}
@media (max-width: 1023px) {
  .mobile-navigation div.sub1 {
    padding: 0 5vw;
  }
}
@media (max-width: 767px) {
  .mobile-navigation div.sub1 {
    margin-top: 100px;
  }
}
.mobile-navigation div.sub1 > .item {
  margin: 7px 0;
}
.mobile-navigation div.sub1 > .item.init {
  margin-top: 0;
}
.mobile-navigation div.sub1 > .item.exit {
  margin-bottom: 0;
}
.mobile-navigation div.sub1 > .item > .menu {
  padding-right: 42px;
  font-size: 24px;
  line-height: 1.41666667;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .mobile-navigation div.sub1 > .item > .menu {
    font-size: 18px;
    line-height: 1.88888889;
  }
}
.mobile-navigation div.sub2 {
  box-sizing: border-box;
  padding-left: 20px;
}
.mobile-navigation div.sub2 > .item {
  margin: 5px 0;
}
.mobile-navigation div.sub2 > .item.init {
  display: none;
}
.mobile-navigation div.sub2 > .item.init + .item {
  margin-top: 15px;
}
.mobile-navigation div.sub2 > .item.exit {
  margin-bottom: 15px;
}
.mobile-navigation div.sub2 > .item > .menu {
  font-size: 24px;
  line-height: 1.25;
}
@media (max-width: 767px) {
  .mobile-navigation div.sub2 > .item > .menu {
    font-size: 18px;
    line-height: 1.44444444;
  }
}
@media (min-width: 1024px) {
  .mobile-navigation div.sub1 > .item > .menu:hover,
  .mobile-navigation div.sub2 > .item > .menu:hover,
  .mobile-navigation div.sub1 > .item > .menu:focus,
  .mobile-navigation div.sub2 > .item > .menu:focus {
    color: #FFC400;
  }
}
.mobile-navigation div.sub2 {
  overflow: hidden;
  max-height: 0;
}
.mobile-navigation div.sub2 > .item {
  opacity: 0;
  transform: scale(0.8);
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi {
  transition: none;
  max-height: 1000px;
  transition: max-height 2s;
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi > .item {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s;
}
.mobile-navigation .cb-toggle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
  width: 42px;
  height: 34px;
  background-size: 24px 10px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-down-white.svg);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.mobile-navigation .cb-toggle:hover,
.mobile-navigation .cb-toggle:focus {
  background-image: url(/images/arrow-down-yellow.svg);
}
.mobile-navigation .cb-toggle.cb-toggle-active {
  transform: rotate(-180deg);
  background-image: url(/images/arrow-down-yellow.svg);
}
.desk {
  max-width: 90%;
}
.cb-home {
  margin-left: 5%;
  height: 32px;
  margin: 17px 0 17px 5%;
}
.whitehome {
  left: 5%;
  top: 17px;
  height: 32px;
}
.cb-pages {
  margin-top: 66px;
}
.cb-page-selected-layout3 .cb-home {
  opacity: 0;
  transition: all 0.4s;
}
.cb-page-selected-layout3 .whitehome {
  display: block;
  transition: all 0.4s;
}
.cb-page-selected-layout3 .section--header {
  background-color: transparent;
  transition: all 0.4s;
}
.cb-page-selected-layout3 .tline {
  background-color: #fff;
}
.cb-page-selected-layout3 #services .meta {
  color: #fff;
  background-image: url(/images/service-kursplan-white.svg);
}
.cb-page-selected-layout3 #services .meta:hover,
.cb-page-selected-layout3 #services .meta:focus {
  color: #FFC400;
  background-image: url(/images/service-kursplan-yellow.svg);
}
.cb-page-selected-layout3 #services .meta.service_oeffnungszeiten {
  background-image: url(/images/service-oeffnungszeiten-white.svg);
}
.cb-page-selected-layout3 #services .meta.service_oeffnungszeiten:hover,
.cb-page-selected-layout3 #services .meta.service_oeffnungszeiten:focus {
  background-image: url(/images/service-oeffnungszeiten-yellow.svg);
}
.cb-page-selected-layout3 #services .meta.service_kontakt {
  background-image: url(/images/service-kontakt-white.svg);
}
.cb-page-selected-layout3 #services .meta.service_kontakt:hover,
.cb-page-selected-layout3 #services .meta.service_kontakt:focus {
  background-image: url(/images/service-kontakt-yellow.svg);
}
.cb-page-selected-layout3 #services .meta.service_standorte {
  background-image: url(/images/service-standorte-white.svg);
}
.cb-page-selected-layout3 #services .meta.service_standorte:hover,
.cb-page-selected-layout3 #services .meta.service_standorte:focus {
  background-image: url(/images/service-standorte-yellow.svg);
}
.cb-page-selected-layout3.cb-toggle-target-active .section--header,
.cb-page-selected-layout3 .section--header.cb-scroll-triggered--active {
  background-color: #fff;
}
.cb-page-selected-layout3.cb-toggle-target-active .section--header .cb-home,
.cb-page-selected-layout3 .section--header.cb-scroll-triggered--active .cb-home {
  opacity: 1;
}
.cb-page-selected-layout3.cb-toggle-target-active .section--header .whitehome,
.cb-page-selected-layout3 .section--header.cb-scroll-triggered--active .whitehome {
  opacity: 0;
}
.cb-page-selected-layout3.cb-toggle-target-active .section--header .tline,
.cb-page-selected-layout3 .section--header.cb-scroll-triggered--active .tline {
  background-color: #0075A3;
}
.cb-page-selected-layout3.cb-toggle-target-active .section--header #services .meta,
.cb-page-selected-layout3 .section--header.cb-scroll-triggered--active #services .meta {
  color: #0075A3;
  background-image: url(/images/service-kursplan-blue.svg);
}
.cb-page-selected-layout3.cb-toggle-target-active .section--header #services .meta:hover,
.cb-page-selected-layout3 .section--header.cb-scroll-triggered--active #services .meta:hover,
.cb-page-selected-layout3.cb-toggle-target-active .section--header #services .meta:focus,
.cb-page-selected-layout3 .section--header.cb-scroll-triggered--active #services .meta:focus {
  color: #FFC400;
  background-image: url(/images/service-kursplan-yellow.svg);
}
.cb-page-selected-layout3.cb-toggle-target-active .section--header #services .meta.service_oeffnungszeiten,
.cb-page-selected-layout3 .section--header.cb-scroll-triggered--active #services .meta.service_oeffnungszeiten {
  background-image: url(/images/service-oeffnungszeiten-blue.svg);
}
.cb-page-selected-layout3.cb-toggle-target-active .section--header #services .meta.service_oeffnungszeiten:hover,
.cb-page-selected-layout3 .section--header.cb-scroll-triggered--active #services .meta.service_oeffnungszeiten:hover,
.cb-page-selected-layout3.cb-toggle-target-active .section--header #services .meta.service_oeffnungszeiten:focus,
.cb-page-selected-layout3 .section--header.cb-scroll-triggered--active #services .meta.service_oeffnungszeiten:focus {
  background-image: url(/images/service-oeffnungszeiten-yellow.svg);
}
.cb-page-selected-layout3.cb-toggle-target-active .section--header #services .meta.service_kontakt,
.cb-page-selected-layout3 .section--header.cb-scroll-triggered--active #services .meta.service_kontakt {
  background-image: url(/images/service-kontakt-blue.svg);
}
.cb-page-selected-layout3.cb-toggle-target-active .section--header #services .meta.service_kontakt:hover,
.cb-page-selected-layout3 .section--header.cb-scroll-triggered--active #services .meta.service_kontakt:hover,
.cb-page-selected-layout3.cb-toggle-target-active .section--header #services .meta.service_kontakt:focus,
.cb-page-selected-layout3 .section--header.cb-scroll-triggered--active #services .meta.service_kontakt:focus {
  background-image: url(/images/service-kontakt-yellow.svg);
}
.cb-page-selected-layout3.cb-toggle-target-active .section--header #services .meta.service_standorte,
.cb-page-selected-layout3 .section--header.cb-scroll-triggered--active #services .meta.service_standorte {
  background-image: url(/images/service-standorte-blue.svg);
}
.cb-page-selected-layout3.cb-toggle-target-active .section--header #services .meta.service_standorte:hover,
.cb-page-selected-layout3 .section--header.cb-scroll-triggered--active #services .meta.service_standorte:hover,
.cb-page-selected-layout3.cb-toggle-target-active .section--header #services .meta.service_standorte:focus,
.cb-page-selected-layout3 .section--header.cb-scroll-triggered--active #services .meta.service_standorte:focus {
  background-image: url(/images/service-standorte-yellow.svg);
}
@media (max-width: 767px) {
  .footpart {
    width: 100%;
  }
  .footpart.footpart--2 {
    order: 1;
    margin-top: 30px;
    height: auto;
    padding: 0;
    align-items: center;
  }
  .footpart.footpart--1 {
    order: 2;
  }
  .footpart.footpart--3 {
    order: 3;
  }
  .footpart.footpart--4 {
    order: 4;
  }
  .foottitle {
    margin-bottom: 10px;
  }
  .footlinks {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  #legal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  #legal .meta {
    margin: 0 !important;
  }
}
@media (max-width: 767px) {
  .cb-page-layout1 h1,
  .cb-page-layout3 h1 {
    font-size: 20px;
    line-height: 1.4;
  }
}
@media (max-width: 767px) {
  .farnorth {
    font-size: 12px;
    line-height: 1.16666667;
  }
  .farnorth strong {
    font-size: 14px;
    line-height: 1.42857143;
  }
  .farnorth .unit:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
    background-image: linear-gradient(to bottom, transparent, rgba(0, 45, 64, 0.75));
    opacity: 0.34;
    pointer-events: none;
  }
  .farnorth .unit .text a.open {
    background-size: 12px 5px;
    background-position: center bottom 8px;
    padding-bottom: 20px;
    padding-top: 8px;
  }
  .cb-page-layout3 .farnorth .unit .text a.open {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}
@media (max-width: 767px) {
  .side .seam,
  .base .seam {
    margin-top: 30px;
    padding-top: 45px;
  }
  .side .seam {
    border-top: 2px solid #fff;
  }
  .base .seam {
    border-top: 2px solid #E5E5E5;
  }
}
/*# sourceMappingURL=./screen-small.css.map */